import { Alert, Avatar, Container } from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useSignInIpMutation } from "@/stores/api";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthentication from "@/hooks/useAuthentication";

export default function SignIn() {
  const [signIn, { error }] = useSignInIpMutation();
  const { token } = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    signIn();
  };
  useEffect(() => {
    signIn();
  }, [signIn]);
  useEffect(() => {
    if (token) {
      navigate(from || "/", { replace: true });
    }
  }, [token, from, navigate]);

  return (
    <Container component="main" maxWidth="xs" sx={{ width: "100%" }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h6">
          Connexion
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Alert severity="error" sx={{ textTransform: "uppercase" }}>
            <Typography>
              VOTRE KIOSQUE DE PRESSE NUMERIQUE EST ACCESSIBLE UNIQUEMENT
              LORSQUE VOUS ÊTES CONNECTE(E) AU WIFI OU AVOIR SCANNER UN QR CODE
              DE VOTRE SALON GRAND VOYAGEURS TGV INOUI.
            </Typography>
            {"\n\n"}
            {/* @ts-ignore */}
            <Typography>{error ? error?.data?.message : ""}</Typography>
          </Alert>
        </Box>
      </Box>
    </Container>
  );
}
