// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { camelizeKeys } from 'humps';
import { RootState } from 'stores';
import { AuthResult, Issue, ListResponse, KioskPublicationCategory, Login, providesListWithId, User } from './types';


export const API_KEY = process.env.REACT_APP_API_SECRETE!
export const API_BASE_URL = process.env.REACT_APP_API_URL!

const transformIssue = (publicationNumber: any, publication?: any): Issue => ({
  ...publicationNumber,
  id: publicationNumber.id,
  name: publicationNumber?.publicationName || publication?.name,
  publicationId: publicationNumber?.publicationId || publication?.id,
  publicationName: publicationNumber?.publicationName || publication?.name,
  countPages: publicationNumber?.countPages,
  coverUrl: publicationNumber.coverUrl,
  // pdfUrl: publicationNumber.pdfUrl,
  date: publicationNumber.publishedAt,
  number: publicationNumber.number,
  ojd: publicationNumber.ojd ? {
    ...publicationNumber.ojd,
  } : null,
  publicationNumber: publicationNumber
})
const transformIssues = (data: any[]) => {
  return data.map(transformIssue)
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState, }) => {
    const token = (getState() as RootState)?.auth?.token

    headers.set('locale', 'fr')
    headers.set('X-Accept-Version', '3')

    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `${token}`)
    } else {
      headers.set('authorization', `${API_KEY}`)
    }

    return headers
  },

})

export const api = createApi({
  reducerPath: 'api',
  refetchOnReconnect: true,
  tagTypes: ['Profile', 'Kiosk', 'UserPublication', 'KioskCategory'],

  baseQuery: baseQuery,
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthResult, Login | void>({
      query: (data) => ({
        url: '/authentication',
        method: 'POST',
        body: {
          kiosk: process.env.REACT_APP_KIOSK_ID,
          ...data
        }
      }),
    }),
    signInIp: builder.mutation<AuthResult, void>({
      query: () => ({
        url: '/authentication/ip',
        method: 'POST',
        body: {
          kiosk: process.env.REACT_APP_KIOSK_ID,
        }
      }),
    }),
    registration: builder.mutation<any, Partial<User>>({
      query: (data) => ({
        url: '/registration',
        method: 'POST',
        body: {
          kiosk: process.env.REACT_APP_KIOSK_ID,
          ...data
        }
      }),
    }),
    confirmRegistration: builder.mutation<{ token: string }, string>({
      query: (token) => ({
        url: `/registration/confirm/${token}`,
        method: 'POST',
      }),
    }),
    checkKioskCodeAvailability: builder.mutation<{ available: boolean }, string>({
      query: (code) => ({
        url: `/kiosk_access_codes/${code}/available`, method: 'POST',
      }),
    }),
    kioskCodeToken: builder.mutation<{ token: string }, string>({
      query: (code) => ({
        url: `/kiosk_access_codes/token`,
        method: 'POST',
        body: {
          code,
          kiosk: process.env.REACT_APP_KIOSK_ID
        },
      }),
    }),
    checkKioskQrCodeAvailability: builder.mutation<{ token: string }, string>({
      query: (code) => ({
        url: `/kiosk/${process.env.REACT_APP_KIOSK_ID}/is_qrcode_available/${code}`,
      }),
    }),
    resettingRequestPassword: builder.mutation<{ message?: string }, string>({
      query: (email) => ({
        url: `/resetting/request/password`,
        method: 'POST',
        body: {
          email,
          kiosk: process.env.REACT_APP_KIOSK_ID
        },
      }),
    }),
    resettingResetPassword: builder.mutation<{ token: string }, { token: string, [key: string]: any }>({
      query: ({ token, ...data }) => ({
        url: `/resetting/reset/password/${token}`,
        method: 'POST',
        body: {
          ...data,
          kiosk: process.env.REACT_APP_KIOSK_ID
        },
      }),
    }),
    resettingRequestPasswordApp: builder.mutation<any, string>({
      query: (email) => ({
        url: `/resetting/request/password/app`,
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    profile: builder.query<User, void>({
      query: () => ({
        url: `/reader`,
      }),
      transformResponse: (result) => camelizeKeys(result) as User,
    }),
    categories: builder.query<KioskPublicationCategory[], void>({
      query: () => ({
        url: '/kiosk/categories',
        method: 'GET',
        params: {},
      }),
      transformResponse: (baseQueryReturnValue: KioskPublicationCategory[]) => camelizeKeys(baseQueryReturnValue) as any,
    }),
    issues: builder.query<Issue[], void>({
      query: () => ({
        url: '/kiosk/issues',
        method: 'GET',
        params: {},
      }),
      transformResponse: (baseQueryReturnValue: KioskPublicationCategory[]) => camelizeKeys(baseQueryReturnValue) as any,
    }),
    lastUpdatedissues: builder.query<Issue[], void>({
      query: () => ({
        url: '/kiosk/publication_issues',
        method: 'GET',
        params: {
          last_updated: true
        },
      }),
      // transformResponse: (baseQueryReturnValue: KioskPublicationCategory[]) => camelizeKeys(baseQueryReturnValue) as any,

      providesTags: (result) => [...providesListWithId(result, 'Kiosk'), 'Profile'],
      transformResponse: (response: any) => camelizeKeys(transformIssues(response?.results) || response) as any
    }),
    kioskAccessCodes: builder.mutation<{ url: string, code: string }, void>({
      query: () => ({
        url: '/kiosk_access_codes',
        method: 'POST',
        params: {
          kiosk: process.env.REACT_APP_KIOSK_ID
        },
        body: {
          kiosk: process.env.REACT_APP_KIOSK_ID,
        }
      }),
    }),
    publicationIssues: builder.query<ListResponse<Issue>, { publicationId: number | string, [key: string]: any }>({
      query: ({ publicationId, ...params }) => ({
        url: `/publications/${publicationId}/issues`,
        method: 'GET',
        params: {
          ...params,
          // limit: 50,
          // years: 100-==
        },
      }),
      // transformResponse: (baseQueryReturnValue: KioskPublicationCategory[]) => camelizeKeys(baseQueryReturnValue) as any,
      transformResponse: (response: any) => ({ ...camelizeKeys(response) as any, items: transformIssues(camelizeKeys(response?.results) as any[]) })
    }),
    fetchIssue: builder.query<Issue, number | string>({
      query: (id) => ({
        url: `/publications/issues/${id}`,
        params: {
          pdf: true,
        }
      }),
      transformResponse: (response: any) => transformIssue(camelizeKeys(response) as any)
    }),
  }),
})

export const {
  useSignInMutation,
  useSignInIpMutation,
  useRegistrationMutation,
  useConfirmRegistrationMutation,
  useResettingRequestPasswordMutation,
  useResettingRequestPasswordAppMutation,
  useResettingResetPasswordMutation,
  useCheckKioskCodeAvailabilityMutation,
  useKioskCodeTokenMutation,
  useCheckKioskQrCodeAvailabilityMutation,
  useCategoriesQuery,
  useKioskAccessCodesMutation,
  useIssuesQuery,
  useLastUpdatedissuesQuery,
  usePublicationIssuesQuery,
  useFetchIssueQuery,
  useProfileQuery,
} = api