
// import analytics from '@react-native-firebase/analytics';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { merge } from 'lodash';
import { PURGE } from "redux-persist";
import { api } from './api';
import { AuthResult, User } from './types';

const __DEV__ = process.env.NODE_ENV === "development"

type State = AuthResult & {
  profile?: User | null
  kioskCode?: string | null
  qrCode?: string | null
}
export const authInitialState: State = {
  token: null,
  refreshToken: null,
  id: null,
  kioskCode: null,
  qrCode: null,
  profile: null,
}
// const analytics: {[key:string]:any} = {}
export const logout = createAction<void>('logout')

const setAnalyctsUserInfo = async (user?: User) => {

  if (user) {
    const analyticsUserProperties = {
      fullName: user?.fullName,
      activityLevelName: user?.activityLevel?.name || null,
      fitnessLevelName: user?.fitnessLevel?.name || null,
      workoutGoalName: user?.workoutGoal?.name || null,
    }
    __DEV__ && console.log('analyticsUserProperties', analyticsUserProperties)
    // await analytics().setUserProperties(analyticsUserProperties)
  }
}
export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setRefreshToken(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload
    },
    setAuthData(state, action: PayloadAction<AuthResult>) {
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.id = action.payload.id
    },
  },
  extraReducers: (builder) => {

    builder
      .addCase(logout, (state) => {
        __DEV__ && console.log('logout', state)
        merge(state, {}, authInitialState)
      })
      .addCase(PURGE, (state, action) => {
        __DEV__ && console.log('PURGE auth from  ', state, ' to ', authInitialState, 'action', action)
        state.token = undefined
        state.refreshToken = undefined
        state.id = undefined
      })
      .addMatcher(api.endpoints.signIn.matchFulfilled, (state, action) => {
        __DEV__ && console.log('ConnectedUser', action.payload)
        state.token = action.payload.token;
        state.refreshToken = action.payload.refreshToken || action.payload.refresh_token;
        state.id = action.payload.id;
      })
      .addMatcher(api.endpoints.signInIp.matchFulfilled, (state, action) => {
        __DEV__ && console.log('ConnectedUser', action.payload)
        state.token = action.payload.token;
        state.refreshToken = action.payload.refreshToken || action.payload.refresh_token;
        state.id = action.payload.id;
      })
      .addMatcher(api.endpoints.confirmRegistration.matchFulfilled, (state, action) => {
        __DEV__ && console.log('ConnectedUser', action.payload)
        state.token = action.payload.token;
      })
      .addMatcher(api.endpoints.resettingResetPassword.matchFulfilled, (state, action) => {
        __DEV__ && console.log('ConnectedUser', action.payload)
        state.token = action.payload.token;
      })
      // .addMatcher(api.endpoints.register.matchFulfilled, (state, action) => {
      //   __DEV__ && console.log('ConnectedUser', action.payload)
      //   state.token = action.payload.token;
      //   state.refreshToken = action.payload.refreshToken || action.payload.refresh_token;
      //   state.id = action.payload.id;
      // })
      .addMatcher(api.endpoints.profile.matchFulfilled, (state, action) => {
        __DEV__ && console.log('profile', action.payload)
        state.profile = action.payload;
        setAnalyctsUserInfo(action.payload)
      })
      .addMatcher(api.endpoints.profile.matchFulfilled, (state, action) => {
        __DEV__ && console.log('profile', action.payload)
        state.profile = action.payload;
        setAnalyctsUserInfo(action.payload)
      })
      .addMatcher(api.endpoints.kioskCodeToken.matchFulfilled, (state, action) => {
        state.kioskCode = action.meta.arg.originalArgs;
        state.token = action.payload.token;
      })
      .addMatcher(api.endpoints.checkKioskCodeAvailability.matchFulfilled, (state, action) => {
        if (!action.payload.available) {
          state.kioskCode = undefined
          state.token = undefined
        }
      })
      .addMatcher(api.endpoints.checkKioskQrCodeAvailability.matchFulfilled, (state, action) => {
        state.qrCode = action.meta.arg.originalArgs;
        state.token = action.payload.token;
      })
      .addMatcher(api.endpoints.checkKioskQrCodeAvailability.matchRejected, (state, action) => {
        state.qrCode = undefined;
        state.token = undefined;
      })
      ;
  }
})


// Action creators are generated for each case reducer function
export const { setToken, setRefreshToken, setAuthData, } = authSlice.actions

export default authSlice.reducer