import useIsAuthenticated from '@/hooks/useIsAuthenticated';
import { useCheckKioskQrCodeAvailabilityMutation, } from '@/stores/api';
import { Box, CircularProgress, Container, } from '@mui/material';

import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function KiokQrCode() {

    const { code } = useParams()
    const navigate = useNavigate()

    const isAuthenticated = useIsAuthenticated()

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/', { replace: true })
        }
    }, [isAuthenticated, navigate])

    const [checkCodeAvailability, { isLoading: isCheckingCode, error: checkingCodeError, }] = useCheckKioskQrCodeAvailabilityMutation()
    useEffect(() => {
        if (code) {
            checkCodeAvailability(code)
        }
    }, [code, checkCodeAvailability])

    const Loading = useMemo(() => {
        if (isCheckingCode) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Typography variant='h4'>Verification...</Typography>
                    <CircularProgress color='primary' />
                </Box>
            )
        }
        return null;
    }, [isCheckingCode])

    const CodeUnAvailable = useMemo(() => {
        if (checkingCodeError) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh'
                    }}>
                    <Typography
                        variant='h4'
                        color={"error.main"}
                        textAlign="center">
                        {/* @ts-ignore */}
                        {checkingCodeError?.data?.message || "Vous n'êtes plus éligible pour accéder au kiosque"}
                    </Typography>
                </Box>
            )
        }
        return null;
    }, [checkingCodeError])

    return (
        <Container maxWidth={false} sx={{ flex: 1, }}>
            {Loading}
            {CodeUnAvailable}
        </Container >
    );
}