import { appThemeOptions } from "@/theme";
import { createTheme, PaletteMode } from "@mui/material";
import { merge } from "lodash";
import { useMemo } from "react";

export default function usePrefersThemeColorScheme(mode?: PaletteMode) {
  // const prefersDarkMode = useMediaQuery(`(prefers-color-scheme: light`);

  const theme = useMemo(() => {
    return createTheme(
      merge(appThemeOptions, {
        palette: {
          mode: "light",
        },
      })
    );
  }, []);
  return theme;
}
