import IssueItem, { ContainerButton, Cover, Issue } from '@/components/IssueItem';
import { useAppDispatch } from '@/stores';
import { api } from '@/stores/api';
import { Issue as IssueType } from '@/stores/types';
import { Button, CircularProgress, Container, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Favorite, PlayCircle } from '@mui/icons-material';


interface Props {
}

export default function Publication(props: Props) {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const { publicationId, issueId } = useParams();
    const [issue, setIssue] = useState<IssueType>()
    const [hasMoreIssues, setHasMoreIssues] = useState(true)
    const [issues, setIssues] = useState<IssueType[]>([])

    useEffect(() => {
        const fetchIssue = async () => {
            if (issueId) {
                const result = await dispatch(api.endpoints.fetchIssue.initiate(issueId)).unwrap()
                setIssue(result)
            }
        }
        fetchIssue()
    }, [dispatch, issueId])

    useEffect(() => {
        if (issues.length > 0 && !issue) {
            setIssue(issues[0])
        }
    }, [issue, issues])

    const loadMoreIssue = async (p: number) => {
        if (publicationId) {
            try {
                const result = await dispatch(api.endpoints.publicationIssues.initiate({ publicationId, page: p })).unwrap()
                setIssues([...issues, ...result.items])
                setHasMoreIssues(result.currentPage < result.lastPage - 1)
            } catch (error) {
                setHasMoreIssues(false)
            }
        }
    }
    return (
        <Container maxWidth={false} sx={{ flex: 1, }}>
            <Box sx={{ mb: 10 }}>
                <Grid container p={2}>
                    <Grid item xs={12} sm={12} md={3} display="flex" alignItems="center" >
                        <ContainerButton  >
                            <Issue elevation={7}>
                                <Cover src={issue?.coverUrl} alt={issue?.publicationName} />
                            </Issue>
                        </ContainerButton>
                    </Grid>
                    <Grid item xs={12} md={9} display="flex"   >
                        {/* <Box m={1} display="flex" alignItems="center" > */}
                        <Container sx={{ display: 'flex', alignItems: 'center' }} >
                            <Grid container    >
                                <Grid item xs={12} md={9}  >
                                    <Typography variant='h4' color="primary">{issue?.publicationName}</Typography>
                                    <Typography variant='h5' color="primary">{dayjs(issue?.date).format('LL')}</Typography>
                                    <Box display={"flex"} flexDirection="column">
                                        <Button startIcon={<PlayCircle />}>
                                            Lire
                                        </Button>
                                        <Button variant="outlined" startIcon={<PlayCircle />}>
                                            Lire
                                        </Button>
                                        <Button variant="contained" endIcon={<Favorite />}>
                                            Favoris
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3} display="flex"  >
                                    <Box sx={{ m: 1 }}>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                        {/* </Box> */}
                    </Grid>
                </Grid>
            </Box>

            <InfiniteScroll
                pageStart={0}
                loadMore={loadMoreIssue}
                hasMore={hasMoreIssues}
                loader={
                    <Box
                        key={`loader${publicationId}`}
                        mt={2}
                        sx={{
                            textAlign: 'center',
                            width: '100%',
                        }}>
                        <CircularProgress color='primary' />
                    </Box>
                }
            >
                <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    // px={10}
                    alignItems="stretch"
                >
                    {issues.map((item, i) => {
                        return (
                            <Grid key={item.id} item xs={6} sm={3} md={2} >
                                <IssueItem
                                    title={item.name}
                                    data-id={item.id}
                                    image={item?.coverUrl!}
                                    publishedAt={item?.dateParution}
                                    onClick={() => navigate(`/publications/${item?.publicationId}/${item?.id}`)}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </InfiniteScroll>
        </Container>
    );
}