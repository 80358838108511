import useIsAuthenticated from '@/hooks/useIsAuthenticated';
import { useCheckKioskCodeAvailabilityMutation, useKioskCodeTokenMutation } from '@/stores/api';
import { Box, CircularProgress, Container, } from '@mui/material';

import Typography from '@mui/material/Typography';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function KiokCode() {

    const { code } = useParams()
    const navigate = useNavigate()

    const isAuthenticated = useIsAuthenticated()

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/', { replace: true })
        }
    }, [isAuthenticated, navigate])

    const [
        checkCodeAvailability,
        {
            isLoading: isCheckingCode,
            error: checkingCodeError,
            data: checkCodeData
        }
    ] = useCheckKioskCodeAvailabilityMutation()
    const [fetchCodeToken, { isLoading, }] = useKioskCodeTokenMutation()
    useEffect(() => {
        if (code) {
            checkCodeAvailability(code)
        }
    }, [code, checkCodeAvailability])

    useEffect(() => {
        if (checkCodeData?.available && code) {
            fetchCodeToken(code)
        }
    }, [fetchCodeToken, checkCodeData?.available, code])

    const Loading = useMemo(() => {
        if (isCheckingCode || isLoading) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Typography variant='h4'>Verification...</Typography>
                    <CircularProgress color='primary' />
                </Box>
            )
        }
        return null;
    }, [isCheckingCode, isLoading])

    const CodeUnAvailable = useMemo(() => {
        if (checkCodeData?.available === false) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh'
                    }}>
                    <Typography
                        variant='h4'
                        color={"error.main"}
                        textAlign="center">
                        {/* @ts-ignore */}
                        {checkingCodeError?.data?.message || "Vous n'êtes plus éligible pour accéder au kiosque"}
                    </Typography>
                </Box>
            )
        }
        return null;
    }, [checkCodeData?.available, checkingCodeError])

    return (
        <Container maxWidth={false} sx={{ flex: 1, }}>
            {Loading}
            {CodeUnAvailable}
        </Container >
    );
}