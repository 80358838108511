import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Fab, Fade, useScrollTrigger } from '@mui/material';
import Footer from './components/Footer';
import { Outlet } from 'react-router-dom';
import { Fragment } from 'react';
import Header from './components/Header';
import './assets/scss/app.scss';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

interface ScrollTopProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children?: React.ReactNode;
}
function ScrollTop(props: ScrollTopProps) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}


export default function Layout(props: Props) {
    return (
        <Fragment>
            <Box
            // sx={{ display: 'flex', minHeight: '100vh', }}
            >


                <Header
                    // navItems={[]}
                    // drawerIcon={require('@/assets/images/logo_groupe_propress.png')}
                />

                <Box sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    minHeight: '100vh',
                }}>
                    <Toolbar id="back-to-top-anchor" />
                    <Outlet />

                </Box>

            </Box>
            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <Footer />
        </Fragment>
    );
}