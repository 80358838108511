import React, { Component } from "react";
import LogoTGVInoui from "@/assets/images/tgvinoui.png";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

export default class Header extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <nav
          className={"header navbar navbar-expand-lg bg-kiosk static-top py-4"}
        >
          <div className="container">
            <Link to="/publications">
              <img
                src={logo}
                className="header-App-logo navbar-brand"
                alt="logo"
              />
            </Link>

            <div className="d-flex flex-fill text-center justify-content-start">
              <h5 className=" text-white text-left le-salon-gv">
                <strong>LE SALON</strong>
                <br />
                GRAND VOYAGEUR
              </h5>
            </div>
          </div>
        </nav> */}
        <Container
          component={"header"}
          className={"header navbar navbar-expand-lg bg-kiosk  "}
          sx={{
            paddingTop: 1,
            paddingBottom: 1,
          }}
          maxWidth={false}
        >
          {/* <div className="container-fluid p-0"> */}

          {/* <div className="container"> */}
          <Container maxWidth={false} sx={{ flex: 1 }}>
            <Link to="/publications" style={{ marginRight: "170px" }}>
              <img
                src={LogoTGVInoui}
                className="header-App-logo navbar-brand mr-4"
                alt="logo"
              />
            </Link>
            <h1 className=" text-white text-left le-salon-gv">
              <strong>LE SALON</strong>
              <br />
              GRAND VOYAGEUR
            </h1>
          </Container>
          {/* </div> */}
          {/* </div> */}

          {/* <div className="d-flex flex-fill text-center justify-content-start">
            <h1 className=" text-white text-left le-salon-gv">
              <strong>LE SALON</strong>
              <br />
              GRAND VOYAGEUR
            </h1>
          </div> */}
        </Container>
      </React.Fragment>
    );
  }
}
