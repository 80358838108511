import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { persistor, store, useAppDispatch } from "@/stores";
import Layout from "./Layout";
import Account from "./screens/account";
import Home from "./screens/Home";
import SignIn from "./screens/authentication/signin";
import AuthenticationLayout from "./screens/authentication";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/system";
import Library from "./screens/library";

import Publication from "./screens/publication";
import { api } from "./stores/api";
import QS from "qs";
import { camelizeKeys } from "humps";
import useAuthentication from "./hooks/useAuthentication";
import KiokCode from "./screens/kiosk-code";
import KiokQrCode from "./screens/kiosk-qr-code";
import usePrefersThemeColorScheme from "./hooks/usePrefersThemeColorScheme";
import OpenApp from "./screens/open-app";

const RequireAuth = ({ children }: { children: React.ReactElement }) => {
  const { token } = useAuthentication();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const hashes = useMemo(() => {
    return camelizeKeys(
      QS.parse(location.hash, {
        ignoreQueryPrefix: true,
        delimiter: /[;,&#/?]/,
      })
    );
  }, [location.hash]) as { [key: string]: any };

  useEffect(() => {
    if (hashes.confirmationToken) {
      navigate(`/authentication/confirm-email/${hashes.confirmationToken}`, {
        replace: true,
      });
    } else if (hashes.recoverToken) {
      navigate(`/authentication/forgot-password/reset/${hashes.recoverToken}`, {
        replace: true,
      });
    }
  }, [hashes, navigate]);
  useEffect(() => {
    if (token) {
      dispatch(api.endpoints.profile.initiate());
    }
  }, [token, dispatch]);

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to="/authentication/signin"
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

function App() {
  const theme = usePrefersThemeColorScheme("light");

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: (
            <RequireAuth>
              <Home />
            </RequireAuth>
          ),
        },
        {
          path: "/library",
          element: (
            <RequireAuth>
              <Library />
            </RequireAuth>
          ),
        },
        {
          path: "/account",
          element: (
            <RequireAuth>
              <Account />
            </RequireAuth>
          ),
        },
        {
          path: "/publications/:publicationId/",
          element: (
            <RequireAuth>
              <Publication />
            </RequireAuth>
          ),
          children: [
            {
              path: "/publications/:publicationId/:issueId",
              element: (
                <RequireAuth>
                  <Publication />
                </RequireAuth>
              ),
            },
          ],
        },
        { path: "*", element: <Home /> },
      ],
    },
    { path: "/access/:code", element: <KiokCode /> },
    { path: "/qr/:code", element: <KiokQrCode /> },
    { path: "/store/:store", element: <OpenApp /> },
    {
      path: "/authentication",
      element: <AuthenticationLayout />,
      children: [
        { index: true, element: <SignIn /> },
        { path: "/authentication/signin", element: <SignIn /> },
        // { path: "/authentication/signup", element: <SignUp /> },
        // { path: "/authentication/forgot-password", element: <ForgotPassword />, },
        // { path: "/authentication/forgot-password/reset/:token", element: <ResetPassword /> },
        // { path: "/authentication/confirm-email/:token", element: <ConfirmEmail /> },
      ],
    },
  ];
  let element = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>{element}</PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
