import { Container, } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isAndroid, isIOS } from "react-device-detect";
import { useAppSelector } from '@/stores';

const APP_STORE = "https://apps.apple.com/fr/app/id1540754027"
const PLAY_STORE = "https://play.google.com/store/apps/details?id=com.lekiosquenumerique.tgvinoui"

export default function OpenApp() {
    const { store } = useParams()
    const kioskCode = useAppSelector(state => state?.auth?.kioskCode)
    useEffect(() => {
        if (isAndroid) {
            // const url = `com.lekiosquenumerique.tgvinoui://access/${kioskCode}#Intent;scheme=https;package=com.lekiosquenumerique.tgvinoui;end`;
            // const sdsd = "intent://ANDROID_HOST/#Intent;scheme=ANDROID_SCHEME;package=APP_PACKAGE;end"
            // const url = "intent://sgv-demo.kioskpress.fr/#/access/${kioskCode}/#Intent;scheme=https;package=com.lekiosquenumerique.tgvinoui;end";
            const url = `intent://sgv-demo.kioskpress.fr/#/access/${kioskCode}#Intent;scheme=https;package=com.lekiosquenumerique.tgvinoui;end`;
            window.location.replace(url);
        } else if (isIOS) {
            window.location.replace(`com.lekiosquenumerique.tgvinoui://access/${kioskCode}`);

            setTimeout(() => {
                window.location.replace(APP_STORE);
            }, 10000);
        }
        else {
            window.location.replace(PLAY_STORE);
        }
        // openapp.open()
    }, [store, kioskCode])
    return (
        <Container maxWidth={false} sx={{ flex: 1, }}>

        </Container >
    );
}