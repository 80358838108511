import styled from "@emotion/styled";
import { CloudDownload } from "@mui/icons-material";
import { Box, ButtonBase, Paper, SxProps, Theme } from "@mui/material";
import dayjs from "dayjs";
import { MouseEventHandler } from "react";

interface Props {
  title: string;
  image: string;
  publishedAt?: string | null;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler;
  onDownload?: MouseEventHandler;
}
export const Title = styled("span")(() => ({
  display: "inline-block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
  fontSize: "1rem",
  fontWeight: "700",
}));
const PublishedAt = styled("span")(() => ({
  display: "inline-block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
  fontSize: "0.8rem",
  fontWeight: "700",
}));
export const Cover = styled("img")(() => ({
  height: "auto",
  maxWidth: "100%",
}));
export const Backdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  // @ts-ignore
  backgroundColor: theme.palette.common.black,
  opacity: 0,
  // @ts-ignore
  // @ts-ignore
  transition: theme.transitions.create("opacity"),
  // display: 'flex',
  // justifyContent: 'center',
  // alignContent: 'center',
  // textAlign: 'center',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .play": {
    // @ts-ignore
    transition: theme.transitions.create("opacity"),
    opacity: 0,
    fontSize: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    "& .play": {
      opacity: 1,
    },
  },
}));

export const ContainerButton = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  maxWidth: "100%",
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiBackdrop-root": {
      opacity: 0.4,
    },
  },
}));

export const Issue = styled(Paper)({
  position: "relative",
  // cursor: 'pointer',
  // '&:hover, &.Mui-focusVisible': {
  //     zIndex: 1,
  //     '& .MuiBackdrop-root': {
  //         opacity: 0.4,
  //     },
  // },
});
const IssueItem = (props: Props) => {
  const { title, image, publishedAt, onClick, onDownload, sx } = props;
  return (
    <ContainerButton>
      <Issue
        elevation={7}
        sx={{
          p: 1,
          width: "100%",
          ...sx,
        }}
      >
        <Cover src={image} alt={title} onClick={onClick} />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              overflow: "overlay",
            }}
          >
            <Title>{title}</Title>
            <PublishedAt>
              {publishedAt ? dayjs(publishedAt).format("ll") : "\u00A0"}
            </PublishedAt>
          </Box>
          <ButtonBase disableRipple onClick={onDownload}>
            <CloudDownload fontSize="large" />
          </ButtonBase>
        </Box>
        {/* <Backdrop className="MuiBackdrop-root" >
                    <PlayCircleOutline
                        className="play"
                        sx={{
                            // fontSize: '100%',
                            color: 'white'
                        }} />
                </Backdrop> */}
      </Issue>
    </ContainerButton>
  );
};
export default IssueItem;
