export interface AnyObject {
    [key: string]: any
}

export interface ListResponse<T> {

    items: T[]
    currentPage: number
    lastPage: number
    numResults: number
    pageSize: number
}
export function providesList<R extends ListResponse<{ id: number | string }>, T extends string>(
    result: R | undefined,
    tagType: T
) {

    return result && result.items
        ? [
            { type: tagType, id: 'LIST' },
            ...result.items?.map(({ id }) => ({ type: tagType, id })),
        ]
        : [{ type: tagType, id: 'LIST' }]
}
// export function providesListWithId<R extends [{ id: number | string }], T extends string>(
export function providesListWithId<R extends { id: number | string }[], T extends string>(
    result: R | undefined,
    tagType: T
) {

    return result
        ? [
            { type: tagType, id: 'LIST' },
            ...result.map(({ id }) => ({ type: tagType, id })),
        ]
        : [{ type: tagType, id: 'LIST' }]
}

export interface Login {
    email?: string | null;
    password?: string | null;
}

export interface AuthResult extends AnyObject {
    token?: string | null
    refreshToken?: string | null
    id?: number | null
}

export interface Image {
    url?: string
    webPath?: string
}
export interface Ojd {
    tag: string
    parutionDate: string
    url: string
}
export interface Publication {
    id: number
    name: string
    language: string
    logo?: Image
    publicationNumber?: Issue
}
export interface Issue {
    [key: string]: any
    id: number
    name: string
    language?: string
    countPages: number
    publicationId: number
    publicationName: string
    coverUrl: string
    dateParution?: string
    date?: string
    number?: string
    pdfUrl?: string
    ojd?: Ojd
}

export interface KioskPublicationCategory {
    id: number
    name: string
    icon?: Image
    color?: string,
    items: KioskPublicationCategoryItem[]
}

export interface KioskPublicationCategoryItem {
    id: number
    name: string
    description?: string
    ojdTag?: string
    position?: number
    ojd?: Ojd,
    publication: Publication,
    publicationNumber?: Issue
}

export interface User extends AnyObject {
    id: number
    email: string
    gender?: string
    firstName?: string
    lastName?: string
    fullName?: string,
    defaultReader?: boolean
}